<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Organization Overview" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <mex-data-table
        :data="organizations"
        :headers="organizationTableHeaders"
        tableClass="foreground"
        :footer-props="{
          'items-per-page-options': getTablePagination.rowsPerPage,
        }"
        :items-per-page="getTablePagination.defaultRowsPerPage"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ $dateFormatter.convertJsonDate(item.createdAt).full }}
        </template>
        <template v-slot:item.organizationSystemUsers="{ item }">
          <v-chip v-for="(sysUser, idx) in item.SystemUsers" :key="idx" class="ma-1">
            <v-icon class="mr-1" x-small>mdi-account</v-icon>
            {{ sysUser.username }}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <mex-btn tooltip="Go to Organization View" icon="mdi-sitemap" iconOnly @click="goToOrganizationView(item.OrganizationID)" />
          <mex-btn tooltip="Delete Organization" icon="mdi-delete" iconOnly @click="deleteOrganization(item.OrganizationID)" />
        </template>
      </mex-data-table>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <mex-btn v-if="writeOrganizations" content="Add an organization" icon="mdi-plus" @click="showAddOrganizationDialog = true" />
      </v-col>
    </v-row>
    <mex-dialog :showDialog="showAddOrganizationDialog" dialogTitle="Add an organization" width="50%">
      <template v-slot:dialog-content>
        <v-row>
          <v-col>
            <v-text-field v-model="organizationName" color="primaryAccent" dense hide-details label="Organization name" outlined />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="organizationCustomerNumber" color="primaryAccent" dense hide-details label="Customer number" outlined />
          </v-col>
        </v-row>
      </template>
      <template slot="dialog-actions">
        <v-row justify="center">
          <v-col cols="auto">
            <mex-btn content="Add Organization" icon="mdi-plus" @click="addOrganization" />
          </v-col>
          <v-col cols="auto">
            <mex-btn content="Discard" icon="mdi-delete" @click="discradAddOrganizationDialog" />
          </v-col>
        </v-row>
      </template>
    </mex-dialog>
    <v-dialog v-model="showDeleteOrganizationDialog" max-width="50%">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this organization? This is irreversible!
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <mex-btn content="Delete Organization" icon="mdi-delete" @click="deleteOrganizationConfirm"/>
          <mex-btn content="Cancel" @click="toDeleteOrganization = null; showDeleteOrganizationDialog = false" />
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OrganizationsService from '../../services/organizations.service';
import requiredPermissions from '../../requiredPermissions';
import tablePagination from '../../config/tablePagination.config';

export default {
  name: 'OrganizationOverview',
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    writeOrganizations() {
      return this.getUserPermissions.includes('write_Customer Administration_Organization Administration');
    },
    getTablePagination() {
      return tablePagination;
    }
  },
  data() {
    return {
      // visualization
      showAddOrganizationDialog: false,
      showDeleteOrganizationDialog: false,
      // new organization
      organizationName: null,
      organizationCustomerNumber: null,
      // data
      organizations: [],
      toDeleteOraganization: null,
      organizationTableHeaders: [
        {
          text: 'Organization name',
          align: 'center',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Customer Number',
          align: 'center',
          sortable: true,
          value: 'customerNumber',
        },
        {
          text: 'Created At',
          align: 'center',
          sortable: true,
          value: 'createdAt',
        },
        {
          text: 'Assigned System Users',
          align: 'center',
          sortable: true,
          value: 'organizationSystemUsers',
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
      hasPermission: false,
    };
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.organizationAdministration, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchOrganizations();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  methods: {
    fetchOrganizations() {
      OrganizationsService.getOrganizations().then((response) => {
        this.organizations = response.data;
      });
    },
    discradAddOrganizationDialog() {
      this.showAddOrganizationDialog = false;
      this.organizationName = null;
      this.organizationCustomerNumber = null;
    },
    addOrganization() {
      OrganizationsService.createOrganization(this.organizationName, this.organizationCustomerNumber)
        .then(() => {
          this.discradAddOrganizationDialog();
          this.fetchOrganizations();
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    goToOrganizationView(organizationID) {
      this.$router.push({ name: 'OrganizationView', params: { id: organizationID } });
    },
    deleteOrganization(organizationID) {
      this.toDeleteOraganization = organizationID;
      this.showDeleteOrganizationDialog = true;
    },
    deleteOrganizationConfirm() {
      OrganizationsService.deleteOrganization(this.toDeleteOraganization)
        .then((response) => {
          this.$toast.success(response.data);
          this.showDeleteOrganizationDialog = false;
          this.toDeleteOraganization = null;
          this.fetchOrganizations();
        })
        .catch((err) => {
          this.toDeleteOraganization = null;
          this.showDeleteOrganizationDialog = false;
          this.$toast.error(err.response.data);
        })
    }
  },
};
</script>

<style>
.extendedClinicRow {
  background-color: var(--v-secondary-base);
}
</style>
